import axios from "axios"
import api from "../api/BaseApi"
//import errorManager from '../utils/ErrorManager';

import {
  ON_START_LOADING,
  ON_SEND_DELETE_ACCOUNT_REQUEST_SUCCESS,
  ON_SEND_DELETE_ACCOUNT_REQUEST_FAIL,
  RESET_ALL_REDUCERS,
  SET_LANG,
} from "./types"

export const onStartLoading = () => {
  return (dispatch) => {
    dispatch({
      type: ON_START_LOADING,
    })
  }
}

export const onDeleteAccountRequest = (userData) => {
  return (dispatch) => {
    axios
      .post(api.deleteAccount, userData)
      .then((res) => {
        dispatch({
          type: ON_SEND_DELETE_ACCOUNT_REQUEST_SUCCESS,
          payload: res,
        })
      })
      .catch((err) => {
        if (err.message === "Network Error") {
        }

        dispatch({
          type: ON_SEND_DELETE_ACCOUNT_REQUEST_FAIL,
          payload: err,
        })
      })
  }
}

export const resetALLReducers = () => {
  return (dispatch) => {
    dispatch({
      type: RESET_ALL_REDUCERS,
    })
  }
}

export const setLanguage = (value) => {
  return (dispatch) => {
    dispatch({
      type: SET_LANG,
      payload: value,
    })
  }
}
