import {
  ON_START_LOADING,
  ON_SEND_DELETE_ACCOUNT_REQUEST_SUCCESS,
  ON_SEND_DELETE_ACCOUNT_REQUEST_FAIL,
  RESET_ALL_REDUCERS,
  SET_LANG,
} from "../actions/types"

const INIT_STATE = {
  userData: {
    firstName: "",
    lastName: "",
    mobilePhone: "",
    email: "",
  },
  isLoading: false,
  isError: false,
  isRequestSent: false,
  showMessage: false,
}

const UserReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case ON_START_LOADING:
      return {
        ...INIT_STATE,
        isLoading: true,
      }
    case ON_SEND_DELETE_ACCOUNT_REQUEST_SUCCESS:
      return {
        ...state,
        userData: action.payload,
        isRequestSent: true,
        isLoading: false,
        isError: false,
      }
    case ON_SEND_DELETE_ACCOUNT_REQUEST_FAIL:
      return {
        ...state,
        userData: {
          firstName: "",
          lastName: "",
          mobilePhone: "",
          email: "",
        },
        isRequestSent: true,
        isLoading: false,
        isError: true,
      }
    case SET_LANG:
      return {
        ...state,
        lang: action.payload,
      }

    case RESET_ALL_REDUCERS:
      return {
        ...INIT_STATE,
      }
    default:
      return state
  }
}

export default UserReducer
