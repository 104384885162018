import React, { Component } from "react"
import { Provider } from "react-redux"
import { BrowserRouter as Router, Route, Switch } from "react-router-dom"
import Store from "./store"

import AppLayout from "./components/AppLayout"
import DeleteAccountScreen from "./components/screens/DeleteAccountScreen"

import "./App.scss"

class App extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    return (
      <Provider store={Store}>
        <Router>
          <AppLayout>
            <Switch>
              {/*<Route
                exact
                path="/ChangePasswordScreen"
                component={ChangePasswordScreen}
              />*/}
              <Route exact path="/" component={DeleteAccountScreen} />
            </Switch>
          </AppLayout>
        </Router>
      </Provider>
    )
  }
}

export default App
