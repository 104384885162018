import React, { Component } from 'react';
import './style.scss';


class ValidationMessage extends Component {

    constructor(props) {
        super(props);
            
    }
    
    render(){
        const {isShow, isShowOk, isShowError,errorMessage, className} = this.props;
        if(isShow && isShowError){
        
            return (
                <div className={'validation-message '+className}>
                    <div className='validation-message-wrapper'>
                        <div className="fa fa-times-circle" aria-hidden="true"></div>
                        <div className='error-text'>{errorMessage}</div>
                    </div>
                </div>
            );
        }else if(isShow && isShowOk){
        
                return (
                <div className={className}>
                    <div className='validation-message-wrapper'>
                        <div className="fa fa-check-circle" aria-hidden="true"></div>
                    </div>
                </div>
            );
        }else{
            return (
                <div className={className}></div>
            );
        }
   }
}

export default ValidationMessage;