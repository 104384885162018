//RESET ALL
export const RESET_ALL_REDUCERS = "RESET_ALL_REDUCERS"

//ON LOADING
export const ON_START_LOADING = "ON_START_LOADING"

//USER
export const ON_SEND_DELETE_ACCOUNT_REQUEST_SUCCESS =
  "ON_SEND_DELETE_ACCOUNT_REQUEST_SUCCESS"
export const ON_SEND_DELETE_ACCOUNT_REQUEST_FAIL =
  "ON_SEND_DELETE_ACCOUNT_REQUEST_FAIL"

//ON CLOSE MESSAGE
export const SET_LANG = "SET_LANG"
