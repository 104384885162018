import React, { Component } from "react"
import { connect } from "react-redux"
import InputField from "../../common/InputField"
import { Button } from "../../common/Button"
import Loader from "../../common/Loader"
import { isValidField } from "../../../utils/FieldsValidation"

import Title from "../../common/Title"

import {
  onStartLoading,
  onDeleteAccountRequest,
  resetALLReducers,
} from "../../../actions"

import "./style.scss"

class DeleteAccountScreen extends Component {
  constructor(props) {
    super(props)
    this.INIT_STATE = {
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",

      he: {
        title: "בקשה למחיקת חשבון",
        failed: "נכשלה",
        successed: "הועברה בהצלחה",
        infoText:
          "חשבונכם יימחק תוך 7 ימי עסקים  כולל כל המידע שלכם שיש ברשותנו ",
        btnText: "המשך",
        firstName: {
          hint: "שם פרטי",
          errorText: "מינימום 2 אותיות ",
        },
        lastName: {
          hint: "שם משפחה",
          errorText: "מינימום 2 אותיות ",
        },
        phoneNumber: {
          hint: "מובייל",
          errorText: "טלפון לא תקין",
        },
        email: {
          hint: "אימייל",
          errorText: "אמייל לא תקין",
        },
      },
      en: {
        title: "Request to delete account",
        failed: "is failed",
        successed: "successfully delevered",
        infoText:
          "Your account will be deleted within 7 business days including all your information that we have",
        btnText: "Continue",
        firstName: {
          hint: "First name",
          errorText: "Minimum 2 letters",
        },
        lastName: {
          hint: "Last name",
          errorText: "Minimum 2 letters",
        },
        phoneNumber: {
          hint: "Mobile phone",
          errorText: "Mobile phone number is not correct",
        },
        email: {
          hint: "Email",
          errorText: "Email  is not correct",
        },
      },

      validField: {
        firstName: {
          type: "text",
          isRequired: true,
          isChecked: false,
          isValid: false,
        },
        lastName: {
          type: "text",
          isRequired: true,
          isChecked: false,
          isValid: false,
        },
        phoneNumber: {
          type: "mobile",
          isRequired: true,
          isChecked: false,
          isValid: false,
        },
        email: {
          type: "email",
          isRequired: true,
          isChecked: false,
          isValid: false,
        },
      },
    }

    this.state = this.INIT_STATE
  }

  componentDidMount() {
    this.state = this.INIT_STATE
  }

  componentDidUpdate(prevProps) {}

  componentWillUnmount() {}

  validateInputValue(inputName, value) {
    let { validField } = this.state
    let field = validField[inputName]
    if (!field) return

    field.isChecked = true
    field.isValid = isValidField(
      value,
      field.type,
      field.isRequired,
      field.compareMinValue,
      field.compareMaxValue
    )
    validField[inputName] = field

    this.setState({
      ...this.state,
      validField,
    })
  }

  onInputFocus(inputName) {
    let { validField } = this.state
    let field = validField[inputName]
    if (!field) return

    field.isChecked = false
    field.isValid = false
    validField[inputName] = field

    this.setState({
      ...this.state,
      validField,
    })
  }

  onContinueClick() {
    const { validField, lastName, firstName, phoneNumber, email } = this.state

    this.validateInputValue("lastName", lastName)
    this.validateInputValue("firstName", firstName)
    this.validateInputValue("phoneNumber", phoneNumber)
    this.validateInputValue("email", email)
    const isValid =
      validField.firstName.isChecked &&
      validField.firstName.isValid &&
      validField.lastName.isChecked &&
      validField.lastName.isValid &&
      validField.phoneNumber.isChecked &&
      validField.phoneNumber.isValid &&
      validField.email.isChecked &&
      validField.email.isValid

    if (!isValid) {
      return
    }
    const newUserData = {
      firstName,
      lastName,
      phoneNumber,
      email,
    }
    this.props.onStartLoading()
    this.props.onDeleteAccountRequest(newUserData)
  }

  setValue(inputName, e) {
    switch (inputName) {
      case "firstName":
        this.setState({ firstName: e.target.value })
        break
      case "lastName":
        this.setState({ lastName: e.target.value })
        break
      case "phoneNumber":
        this.setState({ phoneNumber: e.target.value })
        break
      case "email":
        this.setState({ email: e.target.value })
        break
      default:
        break
    }
  }

  onMsgMatchClickOk(e) {
    this.setState({ isMatchError: false })
  }
  onMsgSuucessClickOk(e) {
    this.props.resetALLReducers()
    //this.props.history.push("/")
  }

  render() {
    const { validField, en, he } = this.state
    const { isLoading, isError, isRequestSent, lang } = this.props
    const title = lang == "EN" ? en.title : he.title

    if (isLoading) {
      return (
        <div className="delete-account-screen">
          <Title text={title} />
          <Loader />
        </div>
      )
    }

    const infoText = lang == "EN" ? en.infoText : he.infoText
    const btnText = lang == "EN" ? en.btnText : he.btnText

    if (isError && isRequestSent) {
      const failed = lang == "EN" ? en.failed : he.failed

      return (
        <div className="delete-account-screen">
          <Title text={title} />
          <Title text={failed} />
          <div></div>
        </div>
      )
    }
    if (!isError && isRequestSent) {
      const successed = lang == "EN" ? en.successed : he.successed

      return (
        <div className="delete-account-screen">
          <Title text={title} />
          <Title text={successed} />
          <div>{infoText}</div>
        </div>
      )
    }

    const firstName = lang == "EN" ? en.firstName : he.firstName
    const lastName = lang == "EN" ? en.lastName : he.lastName
    const phoneNumber = lang == "EN" ? en.phoneNumber : he.phoneNumber
    const email = lang == "EN" ? en.email : he.email

    return (
      <div className="delete-account-screen">
        <Title text={title} />
        <div className="info">{infoText}</div>
        <div className="input-wrapper">
          <InputField
            show={true}
            value={this.state.firstName}
            placeholder={firstName.hint}
            mandatory={true}
            showValidation={validField.firstName.isChecked}
            showValidationOk={validField.firstName.isValid}
            showValidationError={!validField.firstName.isValid}
            validationErrorMessage={firstName.errorText}
            onFocus={(e) => this.onInputFocus("firstName")}
            onBlur={(e) => this.validateInputValue("firstName", e.target.value)}
            onChange={(e) => this.setValue("firstName", e)}
            type="text"
            maxLength={20}
          />
        </div>
        <div className="input-wrapper">
          <InputField
            show={true}
            value={this.state.lastName}
            placeholder={lastName.hint}
            mandatory={true}
            showValidation={validField.lastName.isChecked}
            showValidationOk={validField.lastName.isValid}
            showValidationError={!validField.lastName.isValid}
            validationErrorMessage={lastName.errorText}
            onFocus={(e) => this.onInputFocus("lastName")}
            onBlur={(e) => this.validateInputValue("lastName", e.target.value)}
            onChange={(e) => this.setValue("lastName", e)}
            type="text"
            maxLength={20}
          />
        </div>
        <div className="input-wrapper">
          <InputField
            show={true}
            value={this.state.phoneNumber}
            placeholder={phoneNumber.hint}
            mandatory={true}
            showValidation={validField.phoneNumber.isChecked}
            showValidationOk={validField.phoneNumber.isValid}
            showValidationError={!validField.phoneNumber.isValid}
            validationErrorMessage={phoneNumber.errorText}
            onFocus={(e) => this.onInputFocus("phoneNumber")}
            onBlur={(e) =>
              this.validateInputValue("phoneNumber", e.target.value)
            }
            onChange={(e) => this.setValue("phoneNumber", e)}
            type="phone"
            maxLength={10}
          />
        </div>
        <div className="input-wrapper">
          <InputField
            show={true}
            value={this.state.email}
            placeholder={email.hint}
            mandatory={true}
            showValidation={validField.email.isChecked}
            showValidationOk={validField.email.isValid}
            showValidationError={!validField.email.isValid}
            validationErrorMessage={email.errorText}
            onFocus={(e) => this.onInputFocus("email")}
            onBlur={(e) => this.validateInputValue("email", e.target.value)}
            onChange={(e) => this.setValue("email", e)}
            type="text"
            maxLength={30}
          />
        </div>

        <div className="button-wrapper">
          <Button show={true} onClick={(e) => this.onContinueClick()}>
            {btnText}
          </Button>
        </div>
      </div>
    )
  }
}

const mapStateToProps = ({ users }) => {
  const { isLoading, isError, isRequestSent, lang } = users

  return {
    isLoading,
    isError,
    isRequestSent,
    lang,
  }
}

export default connect(mapStateToProps, {
  onDeleteAccountRequest,
  resetALLReducers,
  onStartLoading,
})(DeleteAccountScreen)
