import React, { Component } from "react"

import { connect } from "react-redux"
import { setLanguage } from "../../../actions/UserActions"
import "./style.scss"

class LanguageMenu extends Component {
  constructor(props) {
    super(props)
    this.INIT_STATE = {
      isActive: false,
    }

    this.state = this.INIT_STATE
  }

  setLanguage(value) {
    this.props.setLanguage(value)
  }

  render() {
    const { lang, ...props } = this.props
    const enIsActive = lang === "EN" ? " active" : ""
    const heIsActive = lang === "EN" ? "" : " active"
    return (
      <div className="lang-menu-wrapper">
        <div
          className={"lang-btn" + heIsActive}
          onClick={() => this.setLanguage("HE")}
        >
          עברית
        </div>
        <div
          className={"lang-btn" + enIsActive}
          onClick={() => this.setLanguage("EN")}
        >
          ENG
        </div>
      </div>
    )
  }
}

const mapStateToProps = ({ users }) => {
  const { lang } = users
  return {
    lang,
  }
}

export default connect(mapStateToProps, { setLanguage })(LanguageMenu)
