import React, { Component } from "react"
import { connect } from "react-redux"
import LanguageMenu from "../../components/common/LanguageMenu"

import logo from "../../assets/images/logo.png"

import "./style.scss"

class AppLayout extends Component {
  constructor(props) {
    super(props)
  }
  render() {
    return (
      <div className="app-wrapper">
        <div className="header-wrapper">
          <div className="logo-wrapper">
            <img src={logo} />
          </div>

          <LanguageMenu />
        </div>

        <div className="content-wrapper">
          <div className="content">{this.props.children}</div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = ({ users }) => {
  const { userData } = users
  return {
    userData,
  }
}
export default connect(mapStateToProps)(AppLayout)

//export default AppLayout;
